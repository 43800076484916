import Error from "components/errors/error";
import { FormEvent, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { localStorageUtil } from "utils/local-storage-util";
import { urls } from "utils/environment-constants";
import { FormSelect, NavFooter, PageHeader, SubHeader, Switch } from '@allsynx/components';
import { Button } from "react-bootstrap";
import { fetchGraphQlAnonymous, fetchGraphQlAuthenticated, postAnonymous, postAuthenticated } from "utils/api-util";
import { HubUserInfo } from "types/hub-user-info";
import { GenericGraphQLResponse } from "types/generic-graphql-response"
import { MfaGenerateCodeResponse } from "types/mfa-generate-code-response";
import { GetUserTypeNumeric, UserTypes } from "enums/user-types";
import { GenericUserRequest } from "types/generic-user-request";
import { ProviderTypes } from "enums/provider-types";
import { NavigationContext } from "contexts/navigation-context";
import { getTokenClaim } from "utils/jwt-util";
import { CompanyInfo } from "types/company-info";

async function getCompanyInfo(webDirectory: string): Promise<CompanyInfo|null> {
	if (webDirectory) {
		const response = await fetchGraphQlAnonymous<CompanyInfo>(
				`query {
						companyInfo(webDirectory:"${webDirectory}") {
							companyId,
							companyName,
							isSelfService,
							useGoogleSignIn
							useMicrosoftSignIn
							useBasicSignIn
						}
					}`
			, "companyInfo"
		);

		return response?.resObj ?? null;
	} else {
		console.error(`Could not fetch company info for webDirectory: [${webDirectory}].`);
		return null;
	}
};

async function getUserInfo(userId: string, userType: string): Promise<HubUserInfo|null> {
	if (userId) {
		const response = await fetchGraphQlAuthenticated<HubUserInfo>(
				`query {
						userInfo (userId: ${userId}, userType: ${userType.toUpperCase()}) {
							userId
							userName
							firstName
							lastName
							userType
							companyId
							loginStatus
							loginLocked
							oidcAssociations {
							  providerTypeId
							}
						}
				}`
			, "userInfo"
			, `${urls.tbhBaseUrl}/employees/index.cfm?message=You+are+not+authorized+to+access+that+page.`
		);

		return response?.resObj ?? null;
	} else {
		console.error(`Could not fetch user info for id: [${userId}].`);
		return null;
	}
};

async function deleteOidcLink(userId: string, userType: string, providerType : string): Promise<GenericGraphQLResponse|null> {
	if (userId) {
		const response = await fetchGraphQlAuthenticated<GenericGraphQLResponse>(
				`mutation {
						deleteOidcUserAssociation (
							userId: ${userId}, 
							userType: ${userType}, 
							providerType: ${providerType}
							) {
							success,
							userErrors
						}
				}`
			, "deleteOidcUserAssociation"
			, `${urls.tbhBaseUrl}/employees/index.cfm?message=You+are+not+authorized+to+access+that+page.`
		);

		return response?.resObj ?? null;
	} else {
		console.error(`Could not fetch user info for id: [${userId}].`);
		return null;
	}
};

async function saveUserLoginSettings(userId: string, userType: string, loginStatus: boolean, loginLocked: boolean): Promise<GenericGraphQLResponse|null> {
	if (userId) {
		const response = await fetchGraphQlAuthenticated<GenericGraphQLResponse>(
				`mutation {
						saveUserLoginSettings (
							userId: ${userId}, 
							userType: ${userType}, 
							loginStatus: ${loginStatus},
							loginLocked: ${loginLocked}
							) {
							success,
							userErrors
						}
				}`
			, "saveUserLoginSettings"
			, `${urls.tbhBaseUrl}/employees/index.cfm?message=You+are+not+authorized+to+access+that+page.`
		);

		return response?.resObj ?? null;
	} else {
		console.error(`Could not fetch user info for id: [${userId}].`);
		return null;
	}
};

function UserSecuritySettings() {

	const { setSideBarNavItems } = useContext(NavigationContext);
	const loadNavData = async (userInfoParam : HubUserInfo) => {
		var claimValue = await getTokenClaim("UserType");
		if(userInfoParam) {
			if(userInfoParam.userType == UserTypes.Employee) {
				if(claimValue == GetUserTypeNumeric(UserTypes.CompanyAdmin).toString()) {
					setSideBarNavItems([
						{
							id: "companyMenu",
							displayName: "Administator Menu",
							href: "#",
							onClick: () => {
								var newUrl = `${urls.tbhBaseUrl}/MenuAdmin.cfm`;
								window.location.replace(newUrl);
							}
						},			  
						{
							id: "employeeMenu",
							displayName: "Employee Menu",
							href: "#",
							onClick: () => {
								var newUrl = `${urls.tbhBaseUrl}/employees/index.cfm`;
								window.location.replace(newUrl);
							}
						},
						])
				}
				else if(claimValue == GetUserTypeNumeric(UserTypes.SystemAdmin).toString()) {
					setSideBarNavItems([
						{
							id: "systemMenu",
							displayName: "System Menu",
							href: "#",
							onClick: () => {
								var newUrl = `${urls.tbhBaseUrl}/MenuSystem.cfm`;
								window.location.replace(newUrl);
							}
						},
						{
							id: "companyMenu",
							displayName: "Company Menu",
							href: "#",
							onClick: () => {
								var newUrl = `${urls.tbhBaseUrl}/MenuAdmin.cfm`;
								window.location.replace(newUrl);
							}
						},			  
						{
							id: "employeeMenu",
							displayName: "Employee Menu",
							href: "#",
							onClick: () => {
								var newUrl = `${urls.tbhBaseUrl}/employees/index.cfm`;
								window.location.replace(newUrl);
							}
						},
						])
				}
			}
			else {
				setSideBarNavItems([
					{
						id: "systemMenu",
						displayName: "System Menu",
						href: "#",
						onClick: () => {
							var newUrl = `${urls.tbhBaseUrl}/MenuSystem.cfm`;
							window.location.replace(newUrl);
						}
					},
					{
						id: "companyMenu",
						displayName: "Company Menu",
						href: "#",
						onClick: () => {
							var newUrl = `${urls.tbhBaseUrl}/MenuAdmin.cfm`;
							window.location.replace(newUrl);
						}
					},			  
					{
						id: "companyAdminMenu",
						displayName: `Admin User`,
						href: "#",
						onClick: () => {
							var newUrl = `${urls.tbhBaseUrl}/Users/UserAddEdit.cfm?EditUserID=${userInfoParam.userId}`;
							window.location.replace(newUrl);
						}
					},
					])
			}
		}
	}
	const [isInitialLoading, setIsInitialLoading] = useState(true);
	const [isMicrosoftLinked, setIsMicrosoftLinked] = useState(false);
	const [isGoogleLinked, setIsGoogleLinked] = useState(false);
	const [companyInfo, setCompanyInfo] = useState<CompanyInfo|null>(null);
	const [userInfo, setUserInfo] = useState<HubUserInfo|null>(null);
	const [disableButtons, setDisableButtons] = useState(false);
	const [codeString, setCodeString] = useState("");
	const [codeMessage, setCodeMessage] = useState("");
	const [loginStatus, setLoginStatus] = useState(false);
	const [loginLocked, setLoginLocked] = useState(false);
	const { webDir, userType, userId } = useParams();

	useEffect(() => {
		loadPageData(webDir, userId, userType).catch(console.error);
	}, [webDir, userId, userType]);

	const loadPageData = async (webDirectoryParam: string | undefined, userIdParam: string | undefined, userTypeParam: string | undefined) => {
		setDisableButtons(true);

		setUserInfo(null);
		setIsMicrosoftLinked(false);
		setIsGoogleLinked(false);
		setCodeString("");
		setCodeMessage("");
		
		if (webDirectoryParam) {
			// load general company info
			const info = await getCompanyInfo(webDirectoryParam);	
			if (info) {
				setCompanyInfo(info);
			}
		}
		
		if(userIdParam && userTypeParam) {
			const info = await getUserInfo(userIdParam, userTypeParam);	
			if (info) {
				await setUserInfo(info);
				setIsMicrosoftLinked(info.oidcAssociations.filter(f=> f.providerTypeId == ProviderTypes.Microsoft).length > 0);
				setIsGoogleLinked(info.oidcAssociations.filter(f=> f.providerTypeId == ProviderTypes.Google).length > 0);
				setLoginStatus(info.loginStatus);
				setLoginLocked(info.loginLocked);
				loadNavData(info).catch(console.error);
			}

		}
		else {
			console.error(`no userIdParam [${userIdParam}]`);
		}

		setIsInitialLoading(false);
		setDisableButtons(false);
	};

	const generateCode = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {		
		e.preventDefault();
		e.stopPropagation();
		setDisableButtons(true);
		
		if(userInfo) {
			const mfaRequest: GenericUserRequest = { userId: userInfo?.userId, companyId: userInfo.companyId ?? 0, userType: GetUserTypeNumeric(userInfo?.userType) };
			const response = await postAuthenticated<MfaGenerateCodeResponse>("/api/mfa/generate", JSON.stringify(mfaRequest), `${urls.tbhBaseUrl}/employees/index.cfm?message=You+are+not+authorized+to+access+that+page.`);
			if(response?.ok) {
				var result = response.resObj as MfaGenerateCodeResponse;
				setCodeString(result.code);
				setCodeMessage(result.codeExpiryMessage);
			}
		}
			
		setDisableButtons(false);
	};

	const unlinkClicked = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, providerType : ProviderTypes) => {
		e.preventDefault();
		e.stopPropagation();
		setDisableButtons(true);
		if(userInfo) {
			var deleteResp = await deleteOidcLink((userInfo?.userId ?? 0).toString(), userInfo?.userType, providerType);		
			if(deleteResp?.success)
			{
				if(providerType == ProviderTypes.Microsoft) {
					setIsMicrosoftLinked(false);
				}
				else if(providerType == ProviderTypes.Google) {
					setIsGoogleLinked(false);
				}
				
			}
		}
		setDisableButtons(false);
		
	}

	const handleSubmit = async (e: FormEvent<HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setDisableButtons(true);
		if(userInfo) {
			var resp = await saveUserLoginSettings((userInfo?.userId ?? 0).toString(), userInfo?.userType, loginStatus, loginLocked);		
			if(resp?.success)
			{	
				var newUrl = `${urls.tbhBaseUrl}/employees/index.cfm?message=Security+Settings+saved.`;
				window.location.replace(newUrl);
			}
		}
		setDisableButtons(false);
	};


	const renderView = () => {
		if(isInitialLoading) {
			return <></>;
		}
		return (
				<div>
					<h3>Security Settings</h3>
					{
						userType === "employee" &&
						<>
							<SubHeader value="Login Settings"></SubHeader>
							<div>	
								<FormSelect 
									className="rightSideSmallBox" 
									label={"EE Enrollment Status"} 
									onChange={(e) => {setLoginStatus(e.target.value == "true")}} 
									value={loginStatus.toString()}
									dataQa={loginStatus.toString()}
									disabled={disableButtons} 
									id="selLoginStatus"
								>
									<option key={"false"} value={"false"}>{"Incomplete"}</option>
									<option key={"true"} value={"true"}>{"Complete"}</option>
								</FormSelect>
								<p className="toggle-header">Login Disabled</p>
								<Switch 
									id="chkLoginLocked" 
									switchType="YesNo" 
									checked={loginLocked} 
									onChange={(e) => { setLoginLocked(e.target.checked);}} 
									disabled={disableButtons} 
								/>
								<br />
								<Button id="btnSave" className="button-settings" onClick={handleSubmit} disabled={disableButtons}>Save</Button>
							</div>
							<br/>
							<br/>
						</>
					}
					{ 
						companyInfo?.useBasicSignIn && userInfo?.userType == UserTypes.Employee ?
							<div>
							<SubHeader value="Security Code"></SubHeader>
							<Button id="btnGenerateCode" className="button-settings" disabled={disableButtons} onClick={generateCode}>Generate Code</Button>
							<br/>
							{
								codeString != "" ?
									<p className="code-text">{codeString}</p>
								:
									<br/>
							}
							{
								codeMessage != "" ?
									<p>{codeMessage}</p>
								:
									<br/>
							}</div>
						: 
							<div></div>
					}
					{
						(companyInfo?.useMicrosoftSignIn || companyInfo?.useGoogleSignIn) ?
							<div>
								<SubHeader value="Linked Accounts"></SubHeader>
								{ 
									companyInfo?.useMicrosoftSignIn ?
										<div>
											<h6>Microsoft Account</h6>
											{
												isMicrosoftLinked ?
													<p>
														<i>Account Linked</i>
														{
															disableButtons ?
																<div/>
															:
																<span className="unlink-link" onClick={(e) => unlinkClicked(e, ProviderTypes.Microsoft)}>Unlink</span>
														}
													</p>
												:
													<p><i>No Account Linked</i></p>
											}
										</div>
									: 
										<div></div>
								}
								{ 
									companyInfo?.useGoogleSignIn ?
										<div>
										<h6>Google Account</h6>
										{
											isGoogleLinked ?
												<p>
													<i>Account Linked</i>
													{ 
														disableButtons ?
															<div/>
														:
															<span className="unlink-link" onClick={(e) => unlinkClicked(e, ProviderTypes.Google)}>Unlink</span>
													}
												</p>
											:
												<p><i>No Account Linked</i></p>
										}</div>
									: 
										<div></div>
								}
							</div>
						:
							<div></div>

					}
					<NavFooter
						className="settings-nav"
						leftButtons={
							<>
								{
									userInfo?.userType == UserTypes.Employee ?
										<NavFooter.Button id="btnBack" buttonType="Back" href={`${urls.tbhBaseUrl}/employees/index.cfm`}/>
									:
										<NavFooter.Button id="btnBack" buttonType="Back" href={`${urls.tbhBaseUrl}/MenuAdmin.cfm`}/>
								}
							</>
						}
						/>	
				</div>	
		); }
	return (
		<>
			{renderView()}
		</>
	);
}

export default UserSecuritySettings;